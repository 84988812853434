import ReactDOM from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import { App } from './App'
import 'index.css'

startApp()

function startApp() {
  ReactDOM.createRoot(document.getElementById('root'))
    .render(
      <BrowserRouter>
        <App />
      </BrowserRouter>
    )
}
