import logo from 'assets/logo.jpg'
export const Header = () => {
    return (
        <div className="flex flex-row justify-between items-center h-16 gap-2">

            <div className="">
                <a href="/" ><img src={logo} className="h-16" alt="logo" /></a>
            </div>

            <div className="flex flex-row gap-2 px-4 py-2">
                <a className="link link-primary link-hover" href="/">Home</a>
                <span className="|" />
                <a className="link link-primary link-hover" href="/about">About Us</a>
                <span className="|" />
                <a className="link link-primary link-hover" href="/contact">Contact us</a>
            </div>
        </div>
    )
}