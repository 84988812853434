export const AboutPage = () => {
    return (
        <div>
            <div className="hero bg-base-200">
                <div className="hero-content text-center">
                    <div className="max-w-md">
                        <h1 className="text-3xl font-bold">ABOUT US</h1>
                        <p className="py-6">Established in 1996, BR-1 America Inc. is a commodities trading, logistics solution provider and master distributor for entertainment companies in Latin America. We operate on a global scale offering integrated services that facilitate the management and execution of the movement of goods, ensuring efficient, cost-effective, and timely delivery of cargoes.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}