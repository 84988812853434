import { Footer } from 'components/Footer';
import { Header } from 'components/Header';
import { AboutPage } from 'pages/AboutPage';
import { ContactPage } from 'pages/ContactPage';
import { HomePage } from 'pages/HomePage';
import { Route, Routes } from 'react-router-dom';

export const App = () => {
  return (
    <div className="flex flex-col h-screen justify-between">
      <Header />
      <main className="mb-auto h-full flex flex-row justify-center items-start py-20">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<AboutPage />} />
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </main>
      <Footer />
    </div>
  );
}
